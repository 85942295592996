
export default {
  name: "ListPropertyNote",

  props: {
    note: {
      type: Object,
      required: true,
    },

    hideDelete: {
      type: Boolean,
      default: false,
    },

    listEntityId: {
      type: String,
      required: true
    },
  },

  data() {
    return {};
  },

  computed: {
    getNoteAgeString() {
      const timeDiffMillis = new Date().getTime() - new Date(this.note.timestamp).getTime();
      if (timeDiffMillis < 3600000) {
        const minutes = Math.floor(timeDiffMillis / 60000);
        return `${minutes < 0 ? 0 : minutes} ${
          minutes === 1 ? this.$t("LISTITEM_NOTE_TIMEUNIT_MINUTE") : this.$t("LISTITEM_NOTE_TIMEUNIT_MINUTES")
        }`;
      } else if (timeDiffMillis < 86400000) {
        const hours = Math.floor(timeDiffMillis / 3600000);
        return `${hours} ${
          hours === 1 ? this.$t("LISTITEM_NOTE_TIMEUNIT_HOUR") : this.$t("LISTITEM_NOTE_TIMEUNIT_HOURS")
        }`;
      } else if (timeDiffMillis < 2629152000) {
        const days = Math.floor(timeDiffMillis / 86400000);
        return `${days} ${days === 1 ? this.$t("LISTITEM_NOTE_TIMEUNIT_DAY") : this.$t("LISTITEM_NOTE_TIMEUNIT_DAYS")}`;
      } else if (timeDiffMillis < 31549824000) {
        const months = Math.floor(timeDiffMillis / 2629152000);
        return `${months} ${
          months === 1 ? this.$t("LISTITEM_NOTE_TIMEUNIT_MONTH") : this.$t("LISTITEM_NOTE_TIMEUNIT_MONTHS")
        }`;
      } else {
        const year = Math.floor(timeDiffMillis / 31549824000);
        return `${year} ${
          year === 1 ? this.$t("LISTITEM_NOTE_TIMEUNIT_YEAR") : this.$t("LISTITEM_NOTE_TIMEUNIT_YEARS")
        })`;
      }
    },
  },

  methods: {
    deleteNote() {
      this.$emit("delete", { id: this.note.id, entityId: this.listEntityId });
    },
  },
};
